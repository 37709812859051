module.exports={
  "_from": "yasgui-yasqe@2.11.21",
  "_id": "yasgui-yasqe@2.11.21",
  "_inBundle": false,
  "_integrity": "sha512-Rj0Shqcj3F1U9Vis3V0Q7d9XKZH8xx64/+Ut1I2QDDOtNL2qdmvr2JR01DeTjwf8HVVfE2zTqjDqWnNoelxwqw==",
  "_location": "/yasgui-yasqe",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "yasgui-yasqe@2.11.21",
    "name": "yasgui-yasqe",
    "escapedName": "yasgui-yasqe",
    "rawSpec": "2.11.21",
    "saveSpec": null,
    "fetchSpec": "2.11.21"
  },
  "_requiredBy": [
    "#USER",
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/yasgui-yasqe/-/yasgui-yasqe-2.11.21.tgz",
  "_shasum": "0394f040494ea8de89020a312d82bd7d7a4698c0",
  "_spec": "yasgui-yasqe@2.11.21",
  "_where": "/home/lrd900/yasgui/yasgui.opentriply",
  "author": {
    "name": "Laurens Rietveld"
  },
  "bugs": {
    "url": "https://github.com/YASGUI/YASQE/issues/"
  },
  "bundleDependencies": false,
  "dependencies": {
    "codemirror": "5.17.0",
    "jquery": "^2.2.4",
    "prettier": "^1.4.4",
    "yasgui-utils": "^1.6.7"
  },
  "deprecated": false,
  "description": "Yet Another SPARQL Query Editor",
  "devDependencies": {
    "bootstrap-sass": "^3.3.7",
    "browserify": "^13.1.0",
    "browserify-shim": "^3.8.12",
    "browserify-transform-tools": "^1.6.0",
    "exorcist": "^0.4.0",
    "gulp": "^3.9.1",
    "gulp-autoprefixer": "^3.1.0",
    "gulp-bump": "^2.2.0",
    "gulp-concat": "^2.6.0",
    "gulp-connect": "^4.2.0",
    "gulp-cssimport": "^3.1.0",
    "gulp-cssnano": "^2.1.2",
    "gulp-embedlr": "^0.5.2",
    "gulp-filter": "^4.0.0",
    "gulp-git": "^2.4.1",
    "gulp-jsvalidate": "^2.1.0",
    "gulp-livereload": "^3.8.1",
    "gulp-notify": "^2.2.0",
    "gulp-rename": "^1.2.2",
    "gulp-sass": "^2.3.2",
    "gulp-sourcemaps": "^1.6.0",
    "gulp-streamify": "1.0.2",
    "gulp-tag-version": "^1.3.0",
    "gulp-uglify": "^1.5.4",
    "node-sass": "^3.8.0",
    "require-dir": "^0.3.2",
    "run-sequence": "^1.2.2",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "~1.1.0",
    "vinyl-transform": "1.0.0",
    "watchify": "^3.7.0"
  },
  "homepage": "http://yasqe.yasgui.org",
  "keywords": [
    "JavaScript",
    "SPARQL",
    "Editor",
    "Semantic Web",
    "Linked Data"
  ],
  "license": "MIT",
  "main": "src/main.js",
  "maintainers": [
    {
      "name": "Laurens Rietveld",
      "email": "laurens.rietveld@gmail.com",
      "url": "http://laurensrietveld.nl"
    }
  ],
  "name": "yasgui-yasqe",
  "optionalShim": {
    "codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    },
    "jquery": {
      "require": "jquery",
      "global": "jQuery"
    },
    "../../lib/codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    }
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/YASGUI/YASQE.git"
  },
  "scripts": {
    "build": "gulp",
    "dev": "gulp serve",
    "major": "gulp major",
    "minor": "gulp minor",
    "patch": "gulp patch",
    "update-interactive": "npm-check --skip-unused -u"
  },
  "version": "2.11.21"
}
