module.exports={
  "_from": "yasgui-utils@^1.6.7",
  "_id": "yasgui-utils@1.6.7",
  "_inBundle": false,
  "_integrity": "sha1-K8/FoxVojeOuYFeIPZrjQrIF8mc=",
  "_location": "/yasgui-utils",
  "_phantomChildren": {},
  "_requested": {
    "type": "range",
    "registry": true,
    "raw": "yasgui-utils@^1.6.7",
    "name": "yasgui-utils",
    "escapedName": "yasgui-utils",
    "rawSpec": "^1.6.7",
    "saveSpec": null,
    "fetchSpec": "^1.6.7"
  },
  "_requiredBy": [
    "/",
    "/yasgui-yasqe",
    "/yasgui-yasr"
  ],
  "_resolved": "https://registry.npmjs.org/yasgui-utils/-/yasgui-utils-1.6.7.tgz",
  "_shasum": "2bcfc5a315688de3ae6057883d9ae342b205f267",
  "_spec": "yasgui-utils@^1.6.7",
  "_where": "/home/lrd900/yasgui/yasgui",
  "author": {
    "name": "Laurens Rietveld"
  },
  "bugs": {
    "url": "https://github.com/YASGUI/Utils/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "store": "^2.0.4"
  },
  "deprecated": false,
  "description": "Utils for YASGUI libs",
  "homepage": "https://github.com/YASGUI/Utils",
  "licenses": [
    {
      "type": "MIT",
      "url": "http://yasgui.github.io/license.txt"
    }
  ],
  "main": "src/main.js",
  "maintainers": [
    {
      "name": "Laurens Rietveld",
      "email": "laurens.rietveld@gmail.com",
      "url": "http://laurensrietveld.nl"
    }
  ],
  "name": "yasgui-utils",
  "repository": {
    "type": "git",
    "url": "git://github.com/YASGUI/Utils.git"
  },
  "version": "1.6.7"
}
