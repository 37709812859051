module.exports={
  "name": "yasgui",
  "description": "Yet Another SPARQL GUI",
  "version": "2.7.29",
  "main": "src/main.js",
  "license": "MIT",
  "author": "Laurens Rietveld",
  "homepage": "http://yasgui.org",
  "scripts": {
    "dev": "gulp serve",
    "build": "gulp",
    "patch": "gulp patch",
    "minor": "gulp minor",
    "major": "gulp major",
    "upgrade-interactive": "npm-check --skip-unused -u"
  },
  "devDependencies": {
    "brfs": "^1.4.3",
    "browserify": "^13.1.0",
    "browserify-transform-tools": "^1.6.0",
    "connect": "^3.4.1",
    "exorcist": "^0.4.0",
    "gulp": "~3.9.1",
    "gulp-autoprefixer": "^3.1.0",
    "gulp-bump": "^2.2.0",
    "gulp-clean-css": "^2.0.12",
    "gulp-concat": "^2.6.0",
    "gulp-connect": "^4.2.0",
    "gulp-delete-lines": "0.0.7",
    "gulp-embedlr": "^0.5.2",
    "gulp-filter": "^4.0.0",
    "gulp-git": "^2.4.1",
    "gulp-jsvalidate": "^2.1.0",
    "gulp-livereload": "^3.8.1",
    "gulp-notify": "^2.2.0",
    "gulp-rename": "^1.2.2",
    "gulp-sass": "^2.3.2",
    "gulp-sourcemaps": "^1.6.0",
    "gulp-streamify": "1.0.2",
    "gulp-tag-version": "^1.3.0",
    "gulp-uglify": "^1.5.4",
    "node-sass": "^3.8.0",
    "require-dir": "^0.3.2",
    "run-sequence": "^1.2.2",
    "stream-browserify": "^2.0.1",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "~1.1.0",
    "vinyl-transform": "1.0.0",
    "watchify": "^3.7.0"
  },
  "bugs": "https://github.com/YASGUI/YASGUI/issues/",
  "keywords": [
    "JavaScript",
    "SPARQL",
    "Editor",
    "Semantic Web",
    "Linked Data"
  ],
  "maintainers": [
    {
      "name": "Laurens Rietveld",
      "email": "laurens.rietveld@gmail.com",
      "web": "http://laurensrietveld.nl"
    }
  ],
  "repository": {
    "type": "git",
    "url": "https://github.com/YASGUI/YASGUI.git"
  },
  "dependencies": {
    "blueimp-md5": "^2.7.0",
    "bootstrap-contextmenu": "^1.0.0",
    "bootstrap-sass": "^3.3.7",
    "browserify-shim": "^3.8.12",
    "jquery": "^2.2.4",
    "jquery-ui": "^1.10.5",
    "microplugin": "0.0.3",
    "minimist": "^1.2.0",
    "npm-check": "^5.4.5",
    "promise-polyfill": "^6.0.2",
    "selectize": "^0.12.4",
    "store": "^2.0.4",
    "underscore": "^1.8.3",
    "url-parse": "^1.1.8",
    "yasgui-utils": "^1.6.7",
    "yasgui-yasqe": "^2.11.21",
    "yasgui-yasr": "^2.12.19"
  },
  "browserify-shim": {
    "jQuery": "jquery"
  },
  "optionalShim": {
    "codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    },
    "jquery": {
      "require": "jquery",
      "global": "jQuery"
    },
    "jQuery": {
      "require": "jquery",
      "global": "jQuery"
    },
    "../../lib/codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    },
    "../lib/DataTables/media/js/jquery.dataTables.js": {
      "require": "datatables",
      "global": "jQuery"
    }
  }
}
